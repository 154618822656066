<template>
  <div class="downapp">
    <img class="logo" :src="require('@/assets/downapp/logo.png')" />
    <img class="text" :src="require('@/assets/downapp/text.png')" />
    <div class="ft">
      <button
        class="btn"
        :disabled="platform.Android && fetching"
        @click="onDown"
      >
        <span class="middle">立即下载</span>
      </button>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import request from '@/utils/request';

export default {
  setup() {
    const platform = reactive({
      Android: /Android/.test(navigator.userAgent),
      iOS: /iPhone|iPad/.test(navigator.userAgent),
      Wechat: /MicroMessenger/.test(navigator.userAgent)
    });
    const apkUrl = ref('');
    const fetching = ref(false);

    const getDownUrl = async () => {
      try {
        fetching.value = true;
        const res = await request.call('Delivery.GetLatestApp', {});
        const json = res.data;
        if (json.Error) {
          console.error(json.Error.detail);
        } else {
          apkUrl.value = json.Data.ApkUrl;
        }
      } catch (error) {
        console.error(error);
      } finally {
        fetching.value = false;
      }
    };

    const onDown = () => {
      if (platform.iOS) {
        location.href = 'https://itunes.apple.com/app/id1538693501';
      } else {
        location.href = apkUrl.value;
        // location.href = 'http://a.app.qq.com/o/simple.jsp?pkgname=com.zhihu.android&ckey=CK1429281819732';
      }
    };

    document.title = '新盒配送 APP 下载';
    getDownUrl();

    return {
      apkUrl,
      fetching,
      platform,
      getDownUrl,
      onDown,
    };
  },
};
</script>

<style lang="less" scope>
.downapp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-image: url(~@/assets/downapp/bg.png);
}

.logo {
  margin-top: 2.8125rem;
  display: block;
  width: 20.125rem;
  height: 3.5rem;
}

.text {
  display: block;
  width: 10.625rem;
  height: 23.125rem;
}

.ft {
  padding-bottom: 3.125rem;
}
.btn {
  display: block;
  margin: 0 auto;
  width: 15.625rem;
  height: 3.125rem;
  color: #fff;
  font-size: 1rem;
  background-color: #222;
  border: 1px solid #222;
  border-radius: 1.5625rem;
  outline: none;

  &:active {
    opacity: 0.9;
  }
  &:disabled {
    opacity: 0.5;
  }
}
</style>
